import { useState, useEffect } from "react";
import Dropdown from "../field/Dropdown";
import TextField from "../field/TextField";
import UploadImage from "../field/UploadImage";
import CheckBox from "../field/CheckBox";
import CheckBoxMulti from "../field/CheckBoxMulti";
import { TaskContext } from "../../contexts/TaskContext";
import { useContext } from "react";
import {
  lineStyle,
  subTitleStyle,
  titleStyle,
  WarningLabel,
  subTitTreament,
  titleStyle_Line_Infection,
  RemarkLabel,
} from "../../hooks/useClassName";
import CheckBoxWithDropDown from "../field/CheckBoxWithDropdown";
import CheckBoxWithTextField from "../field/CheckBoxWithTextField";
import DatePickerField from "../field/DatePickerField";
import {
  AttendingOptions,
  co_rtPAx,
  optionsDeath,
  optionsReasons,
  RNOptions,
  wo_rtPAx,
} from "../../contexts/DataOptions";
import { timeDifference } from "../functions/Functions";
import CheckBoxSelectOne from "../field/CheckboxSelectOne";
import { ListDoctorAndNurseContext } from "../../contexts/ListDoctorAndNurseContext";
import { filterRole } from "../../components/functions/Functions";
import InputTime from "../field/InputTime";
import { WatDatePicker } from "thaidatepicker-react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CheckBoxMultiSelectone from "../field/CheckBoxMultiSelectone";
import CheckBoxForOnChange from "../field/CheckBoxForOnChange";
import api from "../../api";
import TimeDropdown from "../field/DropDownPickTime";

const Summary = ({
  setField,
  form,
  onClickFinalSave,
  disabled,
  seeDetail,
  onClickOutDetail,
  onClickCancle,
  isNurseWard,
}) => {
  console.log("formShort", form);
  // console.log("line_is_no_loangerrrr", form?.line_is_no_loanger);
  // console.log("form?.final_date_success", form?.final_date_success);
  const handleSum = (christDate, buddhistDate) => {
    // console.log(christDate)
    // console.log(buddhistDate);
    setField("line_is_no_loanger")(new Date(christDate).toISOString());
    // setSelectedDate(christDate)
    // setSelectedThaiDate(buddhistDate)
  };
  return (
    <div>
      <div className="">
        <div className={titleStyle}> Line removal / patient deceased date</div>
        <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
          <WatDatePicker
            value={form?.line_is_no_loanger ? form?.line_is_no_loanger : null}
            onChange={handleSum}
            dateFormat={"yyyy-MM-dd"}
            placeholder={"DD/MM/YYYY"}
            displayFormat={"DD MMMM YYYY"}
            inputStyle={{ color: "black", width: "190px" }}
            clearable={true}
            minDate={"1920-01-01"}
            maxDate={dayjs().add(20, "day")}
            disabled={false}
            readOnly={false}
          />
        </div>
      </div>

      <div className={"text-lg text-textinput font-semibold my-5 mx-10"}>
        Total catheter day
      </div>
      <TextField
        setField={setField}
        name="total_cather_day"
        value={
          form?.line_is_no_loanger
            ? timeDifference(
                new Date(`${form?.line_is_no_loanger}`),
                // new Date(
                //   `${form?.attempts_final_obj?.date}T${form?.attempts_final_obj?.line_finalized}:00`
                // )
                new Date(`${form?.final_date_success}`)
              )
            : "กรุณาเลือกวันที่ถอดสาย"
        }
        // unit={form?.attempt_final_date ? "days" : ""}
        disabled={true}
      />
      <CheckBoxMulti
        setField={setField}
        disabled={disabled}
        label={["ยังมีชีวิต", "เสียชีวิต"]}
        name={["summary_alive", "summary_deceased"]}
        value={[form?.summary_alive, form?.summary_deceased]}
        useDynamicDisable
        disableValue={[form?.summary_deceased, form?.summary_alive]}
      />

      <div className="">
        {(form?.summary_deceased || form?.summary_alive) && (
          <>
            <div className={"text-lg text-textinput font-semibold my-5 mx-10"}>
              Reasons for discontinuation
            </div>

            <RemarkLabel alive={form?.summary_alive} />
            <Dropdown
              placeholder={"ระบุเหตุผล"}
              disabled={disabled}
              name="reasons_for_discontinuation"
              value={form?.reasons_for_discontinuation}
              options={form?.summary_deceased ? optionsDeath : optionsReasons}
              setField={setField}
              other_name="other_reasons_for_discontinuation_name"
              other_value={form?.other_reasons_for_discontinuation_name}
            />
          </>
        )}
      </div>

      <CheckBoxSelectOne
        classNameTitle="  text-lg text-textinput font-semibold my-5 mx-10"
        title={"ความพึงพอใจของญาติ  (1 ไม่พอใจ ... 5 พอใจมากที่สุด)"}
        setField={setField}
        name="relative_satisfaction"
        value={form?.relative_satisfaction}
        disabled={disabled}
        label={["1", "2", "3", "4", "5"]}
      />
      <CheckBoxSelectOne
        classNameTitle="  text-lg text-textinput font-semibold my-5 mx-10"
        title={
          "ความพึงพอใจของบุคลากรดูแลผู้ป่วย (1 ไม่พอใจ ... 5 พอใจมากที่สุด)"
        }
        setField={setField}
        name="patient_care_personnel_satisfaction"
        value={form?.patient_care_personnel_satisfaction}
        disabled={disabled}
        label={["1", "2", "3", "4", "5"]}
      />

      {seeDetail ? (
        <div
          onClick={() => onClickOutDetail()}
          className="cursor-pointer my-8 bg-white w-full  hover:bg-white hover:shadow-lg shadow-md  p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
        >
          Cancel
        </div>
      ) : (
        <div className=" flex justify-center gap-10 my-10">
          <div
            onClick={() => {
              if (!form?.line_is_no_loanger) {
                alert("กรุณาเลือกวันที่ถอดสาย");
              }
              if (!form?.reasons_for_discontinuation) {
                alert("กรุณาระบุเหตุผลที่ถอดสาย");
              } else {
                onClickFinalSave();
              }
            }}
            className=" bg-white  hover:bg-white hover:shadow-lg shadow-md  w-36 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
          >
            Final save
          </div>
          <div
            onClick={() => onClickCancle()}
            className="bg-white  hover:bg-white hover:shadow-lg shadow-md w-36 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
          >
            Cancel
          </div>
        </div>
      )}
    </div>
  );
};
const FormSummaryShort = ({
  onClickSaveFollow,
  onClickFinalSave,
  onClickUpdateFollow,
  disabled = false,
  seeDetail,
  onClickOutDetail,
  form,
  onClickCancle,
  count,
  seeDetailId,
  viewOnly,
  setNav,
  nav,
  setSeeDetailId,
  setCurrentFollow,
  currentFollow,
  tables,
  setSeeDetail,
  summaryType,
  setSummaryType,
  create = false,
  loadDateFollow = false,
  loadTimeFollow = false,
}) => {
  const { user } = useSelector((state) => state.auth);

  // console.log("form in : ", form);
  // const count = 1;
  const { setField } = useContext(TaskContext);
  const [summary, setSummary] = useState(false);
  const { listName } = useContext(ListDoctorAndNurseContext);
  const [docandnurses, setDocAndNurses] = useState([]);

  // console.log("listName", listName);

  // console.log("seeDetail", seeDetail);

  const currentTime = new Date().toLocaleTimeString("th-TH", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // console.log("form", form);

  const handleVisit = (christDate, buddhistDate) => {
    setField("date_follow")(christDate);
  };

  const loadDocAndNurse = async () => {
    try {
      // console.log(`Running..`);
      const { data } = await api.get("/profiles", {
        // params: {
        //   role: "NURSE",
        // },
      });

      const { data: userData } = await api.get("/profiles/" + user._id);

      const temp = [userData.profile, ...data.profiles]?.map((el) => ({
        value: el.fullname?.replace(/ /g, ""),
        label: el.fullname,
        role: el.role,
      }));

      // console.log(`form: `, form);
      // console.log(`form?.follow_rn: `, form?.follow_rn);

      if (!form?.follow_rn && !seeDetail) {
        // console.log("2");
        setField("follow_rn")(temp[0]);
      }

      const profileOption = [userData.profile]?.map((el) => ({
        value: el.fullname?.replace(/ /g, ""),
        label: el.fullname,
        role: el.role,
      }));

      const result = temp?.filter((el) => el.role === "NURSE");

      const realResult = [...profileOption, ...result];

      setDocAndNurses(realResult);
    } catch (error) {
      console.error("ERORR :", error);
    }
  };

  useEffect(() => {
    if (form?.follow_rn && docandnurses.length > 0) {
      const thisValue = form?.follow_rn?.value;

      const isExist = docandnurses.find((el) => el.value === thisValue);

      if (!isExist) {
        setDocAndNurses((prev) => [form?.follow_rn, ...prev]);
      }
    }
  }, [form?.follow_rn, docandnurses.length]);

  // console.log(`form?.follow_rn: `, form?.follow_rn);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      loadDocAndNurse();
    }

    return () => {
      // setField("follow_rn")(null);
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && loadDateFollow) {
      // console.log(`In load_date_follow`);
      //2023-12-25"
      const currentDate = new Date().toISOString().split("T")[0];
      setField("date_follow")(currentDate);
    }

    return () => {
      isMounted = false;
    };
  }, [loadDateFollow]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && loadTimeFollow) {
      // console.log(`In load_time_follow`);

      //"16:00"
      setField("time_follow")(currentTime);
    }

    return () => {
      isMounted = false;
    };
  }, [loadTimeFollow]);

  useEffect(() => {
    const isNurseWard = user?.user_department === "NURSEWARD";

    if (isNurseWard && create) {
      setField("by_actial_visit")(true);
    }
    // if (!isNurseWard && create) {
    //   setField("by_phone")(true);
    // }
  }, [user?.user_department, create]);

  console.log(`FormSummaryShort: `, form);

  const isLineRemoved = form?.line_removed;

  return (
    <div className=" ">
      <div className=" md:text-2xl text-xl text-textinput font-bold mt-5 mx-28">
        Follow up
      </div>
      <div className="md:mx-20 mt-10">
        {seeDetail ? (
          <div className="flex justify-between">
            <div className="flex">
              <div className="mx-10 text-xl text-textinput font-semibold">
                Visit {count}
              </div>
            </div>
            <div
              onClick={() => {
                setCurrentFollow(null);
                setSeeDetailId("");
              }}
              className="text-sm cursor-pointer  text-gray-300 hover:text-gray-500  "
            >
              {" "}
              คลิกเพื่อกลับไปที่หน้า ALiST Follow up note
            </div>{" "}
          </div>
        ) : (
          <div className="mx-10 text-xl text-textinput font-semibold">
            Visit {count}
          </div>
        )}

        <div className="">
          <div className={titleStyle}> วัน/เวลา ที่ติดตามคนไข้</div>
          <div className="text-base w-60 p-3 nm-inset-white-sm rounded-md focus:outline-none focus:ring focus:ring-bgbluealistrounded-full  mx-10">
            <WatDatePicker
              value={form?.date_follow}
              onChange={handleVisit}
              dateFormat={"yyyy-MM-dd"}
              displayFormat={"DD MMMM YYYY"}
              placeholder={"DD/MM/YYYY"}
              inputStyle={{ color: "black", width: "190px" }}
              clearable={true}
              minDate={"1920-01-01"}
              maxDate={dayjs().add(20, "day")}
              disabled={false}
              readOnly={false}
            />
          </div>
        </div>
        <TimeDropdown
          setField={setField}
          name="time_follow"
          value={form?.time_follow}
          disabled={disabled}
        />
        {/* <InputTime
          setField={setField}
          name="time_follow"
          value={form?.time_follow}
          disabled={disabled}
        /> */}
        <CheckBoxMultiSelectone
          disabled={disabled}
          title="By"
          name={["by_phone", "by_actial_visit"]}
          label={["โทรศัพท์", "เข้าเยี่ยม"]}
          value={[form?.by_phone, form?.by_actial_visit]}
          setField={setField}
        />
        <Dropdown
          disabled={true}
          title="RN"
          setField={setField}
          name={"follow_rn"}
          value={form?.follow_rn}
          options={docandnurses}

          // options={filterRole(listName, "NURSE")}
          // options={RNOptions}
        />

        <div className={titleStyle}>รายงานการใส่สาย</div>
        <CheckBoxForOnChange
          disabled={disabled}
          label={"แจ้งสายปกติ"}
          setField={setField}
          name={"line_status_ok"}
          value={form?.line_status_ok}
          other_value={["line_status_abnormal", "line_removed"]}
        />
        <CheckBoxForOnChange
          disabled={disabled}
          label={
            "แจ้งสายผิดปกติ (กดปุ่ม save และโทรแจ้ง 41151 หรือ 065-3911216 ในเวลาราชการ)"
          }
          setField={setField}
          name={"line_status_abnormal"}
          value={form?.line_status_abnormal}
          other_value={[
            "line_status_ok",
            "line_removed",
            "line_abnormal_blocked",
            "line_abnormal_infected",
            "line_abonormal_dislocated",
            "line_abnormal_other",
          ]}
        />

        {form?.line_status_abnormal && (
          <div>
            <CheckBoxMultiSelectone
              disabled={disabled}
              setField={setField}
              classNameCover=""
              classnameSelect="md:mx-24 lg:mx-24 xl:mx-10"
              label={["สายตัน", "สายติดเชื้อ", "สายเลื่อน", "ปัญหาอื่น ๆ"]}
              name={[
                "line_abnormal_blocked",
                "line_abnormal_infected",
                "line_abonormal_dislocated",
                "line_abnormal_other",
              ]}
              value={[
                form?.line_abnormal_blocked,
                form?.line_abnormal_infected,
                form?.line_abonormal_dislocated,
                form?.line_abnormal_other,
              ]}
              other_name={"line_abnormal_other_detail"}
              other_value={form?.line_abnormal_other_detail}
            />

            <div className={lineStyle} />
          </div>
        )}
        <CheckBoxForOnChange
          disabled={disabled}
          label={"แจ้งเอาสายออก"}
          setField={setField}
          name={"line_removed"}
          value={form?.line_removed}
          other_value={[
            "line_status_ok",
            "line_status_abnormal",
            "line_abnormal_blocked",
            "line_abnormal_infected",
            "line_abonormal_dislocated",
            "line_abnormal_other",
          ]}
        />

        {form?.line_removed && (
          <div className=" mb-4">
            <div
              onClick={() => setSummary(!summary)}
              class="  cursor-pointer p-2 flex justify-between items-center bg-white "
            >
              <div className="md:text-xl text-base font-bold  text-bgblue mx-12">
                Summary
              </div>
            </div>

            <div className="bg-white p-5">
              <Summary
                onClickOutDetail={onClickOutDetail}
                disabled={disabled}
                setField={setField}
                form={form}
                onClickFinalSave={onClickFinalSave}
                seeDetail={seeDetail}
                onClickCancle={onClickCancle}
              />
            </div>
          </div>
        )}

        {/* {!summary && !seeDetail && ( */}
        {viewOnly ? (
          <div className="text-sm text-center p-4">
            *เนื่องจากลงวันที่ติดตามผู้ป่วยเกิน 24 ชั่วโมงแล้ว
            จึงไม่สามารถแก้ไขรายละเอียดได้อีก
          </div>
        ) : (
          <div
            className={`  flex justify-center gap-5 my-10 ${
              isLineRemoved ? "hidden" : ""
            }`}
          >
            <div
              onClick={() => onClickSaveFollow()}
              className=" bg-white  hover:bg-white hover:shadow-lg shadow-md  w-64 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
            >
              Save
            </div>
            <div
              onClick={() => onClickCancle()}
              className="bg-white  hover:bg-white hover:shadow-lg shadow-md w-64 p-2 rounded-md flex justify-center text-textgrayalist border hover:border-blue-600"
            >
              Cancel
            </div>
          </div>
        )}

        {/* <WarningLabel label="สำหรับการ Follow up ครั้งสุดท้าย กรุณากรอกข้อมูล Summary แล้วคลิก Final save เพื่อสรุปข้อมูล " />
        <WarningLabel label=" * หากกลับเข้ามาแก้ไข visit แล้วไม่มีปุ่ม final save ให้เพิ่ม F/U details อันใหม่ เพื่อกรอก Summay แล้วคลิก Final save * " /> */}
      </div>
    </div>
  );
};
export default FormSummaryShort;
