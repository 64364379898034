import { useDispatch, useSelector } from "react-redux";
import { initAuth } from "../../redux/authSlice";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const Navbar = ({ userDept }) => {
  const dispatch = useDispatch();
  const router = useHistory();

  const { user } = useSelector((state) => state.auth);

  const [userDeptforShow, setUserDeptforShow] = useState("");

  const isNurse =
    user?.user_department === "NURSE" ||
    user?.user_department === "AN" ||
    user?.user_department === "IR" ||
    user?.user_department === "TR" ||
    user?.user_department === "PED" ||
    user?.user_department === "HEMATO" ||
    user?.user_department === "NURSEWARD";

  const onClickSignOut = () => {
    localStorage.clear();
    dispatch(initAuth());
    router.push("/Login");
  };

  useEffect(() => {
    if (userDept === "super_AN" || userDept === "AN") {
      setUserDeptforShow("วิสัญญีวิทยา");
    } else if (userDept === "special_IR" || userDept === "IR") {
      setUserDeptforShow("รังสีร่วมรักษา");
    } else if (userDept === "speacial_TR" || userDept === "TR") {
      setUserDeptforShow("ศัลยศาสตร์อุบัติเหตุ");
    } else if (userDept === "special_PED" || userDept === "PED") {
      setUserDeptforShow("กุมารเวชศาสตร์");
    } else if (userDept === "NURSE" || userDept === "NURSE") {
      setUserDeptforShow("พยาบาล");
    } else if (userDept === "HEMATO") {
      setUserDeptforShow("อายุรศาสตร์โรคเลือด");
    } else if (userDept === "NURSEWARD") {
      setUserDeptforShow("พยาบาลวอร์ด");
    }
  }, [userDept]);

  return (
    <div className="bg-bgbluealist w-full h-full p-8 px-4 md:p-8 md:px-16  ">
      <div className="  flex justify-between items-center  ">
        <div className="cursor-pointer" onClick={() => router.push("/")}>
          <div>
            <img
              src="../Logo/Logo.png"
              className=" h-6 w-auto md:h-10  "
              alt="Logos"
            ></img>
          </div>
          <div className="text-white text-4xl">Home</div>
        </div>
        <div className="flex gap-4">
          {" "}
          {(!isNurse || user?.role === "ADMIN") && (
            <div
              onClick={() => router.push("/Report")}
              className="cursor-pointer hover:underline text-xl text-gray-100 font-bold"
            >
              {" "}
              Report
            </div>
          )}
          {(!isNurse || user?.role === "ADMIN") && (
            <div
              onClick={() => router.push("/Export")}
              className="cursor-pointer hover:underline text-xl text-gray-100 font-bold"
            >
              {" "}
              Export
            </div>
          )}
          {/* <div
          
            onClick={() => router.push("/Test")}
            className="cursor-pointer hover:underline text-xl text-gray-100 font-bold"
          >
            {" "}
            test
          </div> */}
          <div className=" text-gray-100 font-bold md:text-lg text-xs  ">
            {user?.fullname}
            <div className="text-base flex justify-end text-gray-100 font-bold ">
              {userDeptforShow}{" "}
            </div>
            <div
              onClick={() => onClickSignOut()}
              className=" cursor-pointer hover:underline text-xs flex justify-end text-gray-100 font-bold   "
            >
              Sign Out
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
export default Navbar;
