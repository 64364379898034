import AnimatedNumber from "animated-number-react";
import numeral from "numeral";
import Fade from "react-reveal/Fade";

const DashBord = ({ dataChart }) => {
  const infoPatients = [
    {
      state: "รอใส่สาย",
      number: dataChart?.waiting,
      textcolor: " text-chartblue",
    },
    {
      state: "ใส่สายอยู่",
      number: dataChart?.on_iv_tube,
      textcolor: " text-chartyellow",
    },
    {
      state: "ถอดสายแล้ว",
      number: dataChart?.completed,
      textcolor: " text-chartgreen",
    },
    {
      state: "เสียชีวิตหรือขาดการติดต่อ",
      number: dataChart?.deceased,
      textcolor: " text-chartred",
    },
    {
      state: "Central Line",
      number: dataChart?.unfollow,
      textcolor: " text-purple-700",
    },
    {
      state: "ไม่ดำเนินการต่อ",
      number: dataChart?.keep_for_report,
      textcolor: " text-pink-700",
    },
  ];

  // const RenderCount = memo(({ title = "", value = 9999, mode = "number" }) => {
  //   return (
  //     <Fade bottom>
  //       <div className="">
  //         <div className=" text-textgray  font-light text-sm w-full text-center ">
  //           {title}{" "}
  //         </div>
  //         <div className="flex justify-center  items-center space-x-2 text-4xl text-gray-700   font-bold">
  //           {/* <img className="w-5 h-5" src="../icon/coin.png"></img> */}
  //           <AnimatedNumber
  //             value={value}
  //             formatValue={(value) =>
  //               mode === "number"
  //                 ? numeral(value).format("0,0.0000")
  //                 : numeral(value).format("0,0")
  //             }
  //           />
  //           {/* <div className=" text-xl text-black">{text}</div> */}
  //         </div>
  //       </div>
  //     </Fade>
  //   );
  // });

  const Info = ({ state = "", number = "", textcolor = "" }) => {
    return (
      <Fade bottom>
        <div className=" flex justify-between items-center p-3 bg-chartbg w-full rounded-lg  ">
          <div className={"md:text-lg text-base font-bold text-gray-700 "}>
            {state}
          </div>
          <div className={"md:text-3xl text-base  font-bold " + [textcolor]}>
            <AnimatedNumber
              value={number}
              formatValue={(value) => numeral(value).format("0,0")}
            />
          </div>
        </div>
      </Fade>
    );
  };

  return (
    <div>
      <div className=" space-y-5">
        {infoPatients?.map((infoPatien, index) => {
          return (
            <div key={index}>
              <Info
                state={infoPatien?.state}
                number={infoPatien?.number}
                textcolor={infoPatien?.textcolor}
              />
            </div>
          );
        })}
        <div>
          <div className="flex space-x-2">
            <div>เอกสารสำหรับดูแลคนไข้กลับบ้าน</div>
            <a
              href="#"
              className="text-blue-500 underline"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                window.open(
                  "https://www.si.mahidol.ac.th/Th/department/anesthesiology/anesthesia/form/PICC%20line%20home%20care%2021-11-2018.pdf",
                  "_blank"
                );
              }}
            >
              คลิกที่นี่
            </a>
          </div>
          <div className="flex space-x-2">
            <div>วิธี Lock rTPA กรณีสายตัน</div>
            <a
              href="#"
              className="text-blue-500 underline"
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                window.open(
                  "https://www.si.mahidol.ac.th/Th/department/anesthesiology/anesthesia/form/tPA%20catheter%20clearance.pdf",
                  "_blank"
                );
              }}
            >
              คลิกที่นี่
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBord;
